import { Card, Typography } from '@material-tailwind/react'

export default function About() {
  interface bullet_points_section {
    [id: string]: string
  }

  interface release_doc {
    title: string
    version_num: string
    date_released: string
    summary: string
    bullet_points: bullet_points_section
  }

  const release_notes: release_doc[] = [
    {
      title: 'Notifications are out of Beta',
      version_num: '1.2.0',
      date_released: '03/12/2025',
      summary:
        'Notifications preferences have been debugged, and thoroughly tested.',
      bullet_points: {
        'Notification Cleanup':
          'In the upper right, the notifications bell shows when changes are made to your accounts. The view window has been improved with better visuals and the clear button is now at the top.',
      },
    },
    {
      title: 'UPCs and Kompass!',
      version_num: '1.1.0',
      date_released: '04/05/2024',
      summary:
        'This update contained some features I am really excited about and these additions will continue to be improved in the coming weeks.',
      bullet_points: {
        'Kompass Calendar':
          'The calendar can be updated by organizers when it changes. This will be built on in the future, but for now this can allow for faster and more convient access.',
        'UPC Lists':
          'Based on a current list of UPCs I obtained, I have added a serious quantity of upc lists which will be made editable soon!',
        'Misc. Bug Fixes and UI Improvements':
          'The changes to the home screen clean up the My Clients display a bit and allows for better readability on the Change Logs. Additionally, the client page has been cleaned up for privacy and ease of access.)',
      },
    },
    {
      title: 'Bug Fixes and Misc Improvements',
      version_num: '1.0.2',
      date_released: '03/19/2024',
      summary:
        'There have been some minor changes made to solve issues found during testing.',
      bullet_points: {
        'Adding Assistants to Export Builder':
          'Additionally there have been improvements to prevent empty column issues, however these bugs have not been completely eliminated and more work on this feature will be required.',
        'Improved Reloads from Firebase':
          'This should increase how quickly changes are made to the UI, by refreshing from the db more frequently.',
        'Misc. Bug Fixes':
          'There were a handful of other small changes made, including removing scroll bars from the recent changes section of the home page.',
      },
    },
    {
      title: 'Minor Additions',
      version_num: '1.0.1',
      date_released: '02/02/2024',
      summary:
        'With some recent reccomendations I have added a few smaller features.',
      bullet_points: {
        'Marketing Tracker Fields':
          'Allows marketing team to track some key details and export them via a new canned report: "Marketing Tracker"',
        'Improvements to Potential Client Display':
          'On the client list page, a blue "potential" tag is now displayed for client status if potential_client is True for that account.',
        'Misc. Bug Fixes':
          'There were a handful of other small features that were tweaked, including the spacing of payment blocks on client pages.',
      },
    },
    {
      title: 'Initial Release',
      version_num: '1.0.0',
      date_released: '01/28/2024',
      summary:
        'This initial Release included the initial database launch and some headline features.',
      bullet_points: {
        'Custom Exports Tab':
          'Allows you to export a csv based on the fields and filters of your choosing for faster access to data.',
        'Potential Client Tag':
          'On the client page you can choose to mark a client as potential, indicating they are in the interview stage.',
        Logins:
          'On the client page credentials for Stratum, M6 etc. can be stored for rapid access.',
        'Broker Portal Credentials':
          'The settings page for organizers can be used to pick the default credentials of the broker portal. For all clients with broker portal selected in the login field, these presets will be populated.',
        'Comments and Documents':
          'Two new fields have been added to the Client Page, which are free form and not currently exportable. "Documents" can support essentially any file format.',
      },
    },
  ]

  function ReleaseEntries(props: { releaseDoc: release_doc[] }) {
    return (
      <div>
        {props.releaseDoc.map((doc) => (
          <div className="w-2/3 p-2">
            <div className="w-full rounded-lg border-2 p-4">
              <div className="flex w-full justify-between">
                <Typography placeholder={'.'} variant="lead">
                  {doc.version_num}&emsp;-&emsp;{doc.title}
                </Typography>
                <Typography placeholder={'.'} variant="paragraph">
                  {doc.date_released}
                </Typography>
              </div>
              <hr className="border-1.5 border-green-400"></hr>
              <Typography
                variant="paragraph"
                placeholder={'.'}
                className="pt-2"
              >
                {doc.summary}
                <br />
                {Object.keys(doc.bullet_points)
                  .sort((a, b) => a.localeCompare(b))
                  .map((bullet_key) => (
                    <div>
                      <div className="font-semibold">
                        &emsp;&emsp;- {bullet_key}
                      </div>
                      <div className=" px-16">
                        {doc.bullet_points[bullet_key]}
                      </div>
                    </div>
                  ))}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Card placeholder="." className="flex h-full w-full flex-row rounded-lg">
      <div className="flex-coll h-full w-full p-4">
        <div className="flex w-full justify-center">
          <div className="w-fit flex-col">
            <div className="w-full flex flex-col justify-center">
              <Typography variant="h4" placeholder={'.'}>
                What is Orbit?
              </Typography>
              <hr className=" border-2 border-blue-800"></hr>
            </div>
            <div className="w-fit py-6">
              <Typography variant="paragraph" placeholder={'.'}>
                &emsp;The goal of Orbit is to continue our tradition of
                excellent customer service
                <br />
                through faster communication and clear organization. Orbit"s
                primary task is
                <br />
                to track crucial client information and assignments. Allowing
                our team to
                <br />
                continue to focus on delivering results to our cleints and forgo
                time intensive
                <br />
                record keeping.
                <br />
                &emsp;This codebase is live but will have frequent additions and
                patches. Below is a
                <br />
                record of the most recent changes and additions as this tool
                evolves to our needs.
                <br />
                I hope this tool proves useful to you!
                <br />- James Metz
              </Typography>
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="w-fit">
            <Typography variant="h4" placeholder={'.'}>
              Release Notes
            </Typography>
            <hr className="border-2 border-blue-800"></hr>
          </div>
          <ReleaseEntries releaseDoc={release_notes} />
        </div>
      </div>
    </Card>
  )
}
