import {
  Card,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import PermissionsTab from './PermissionsTab'
import AddPaymentsTab from './AddPaymentsTab'
import PresetsTab from './PresetsTab'
import PrevPaymentsTab from './PrevPaymentsTab'
import { useContext } from 'react'
import { PermissionsContext } from '../../contexts'
import ManageAccounts from './ManageAccounts/ManageAccounts'

export default function Settings() {
  const permissions = useContext(PermissionsContext)

  return (
    <Card placeholder="." className="flex h-full w-full flex-col rounded-lg">
      <Typography placeholder={'Settings Page'} variant="h4" className="p-6">
        Settings
      </Typography>
      <hr className="border border-blue-700"></hr>
      <Tabs value="permission" orientation="vertical">
        <TabsHeader placeholder={'.'}>
          {permissions.manage_accounts && (
            <Tab
              placeholder={'.'}
              key={'manage_accounts'}
              value={'manage_accounts'}
              className="h-15 p-4"
            >
              Manage Accounts
            </Tab>
          )}
          {permissions.edit_presets && (
            <Tab
              placeholder={'.'}
              key={'permission'}
              value={'permission'}
              className="h-15 p-4"
            >
              Permissisons
            </Tab>
          )}
          {permissions.edit_permissions && (
            <Tab
              placeholder={'.'}
              key={'presets'}
              value={'presets'}
              className="h-15 p-4"
            >
              Presets
            </Tab>
          )}
          {permissions.view_payments && (
            <Tab
              placeholder={'.'}
              key={'prev-payment'}
              value={'prev-payment'}
              className="h-15 p-4"
            >
              Payment History
            </Tab>
          )}
          {permissions.edit_payments && (
            <Tab
              placeholder={'.'}
              key={'add-payment'}
              value={'add-payment'}
              className="h-15 p-4"
            >
              Add Payments
            </Tab>
          )}
        </TabsHeader>
        <TabsBody placeholder={'.'} className="w-full">
          <TabPanel
            key={'manage_accounts'}
            value={'manage_accounts'}
            className="w-full"
          >
            <ManageAccounts />
          </TabPanel>
          <TabPanel key={'permission'} value={'permission'} className="w-full">
            <PermissionsTab />
          </TabPanel>
          <TabPanel key={'presets'} value={'presets'} className="w-full">
            <PresetsTab />
          </TabPanel>
          <TabPanel
            key={'prev-payment'}
            value={'prev-payment'}
            className="h-full w-full"
          >
            <PrevPaymentsTab />
          </TabPanel>
          <TabPanel
            key={'add-payment'}
            value={'add-payment'}
            className="w-full"
          >
            <AddPaymentsTab />
          </TabPanel>
        </TabsBody>
      </Tabs>
    </Card>
  )
}

/*
Temp Set Aside because it is not ready:



*/
