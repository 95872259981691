import {
  Button,
  Card,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Switch,
  Typography,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'
import { db } from '../../firebase'
import { paymentDoc } from '../../types/paymentDoc'
import Loading from '../../components/Loading'
import { utils, read } from 'xlsx'
import { client } from '../../types/client'
import { ClientsContext } from '../../contexts'

export default function AddPaymentsTab() {
  interface newPaymentRow {
    file_id: string
    value: string // dollar signs and commas are added in ui so removed at this stage.
    month: string // three char string with first letter cappital to indicate the month.
    year: string // example: "2024"
  }
  const [isFilePicked, setPickedFile] = useState(false)
  const clients = useContext(ClientsContext)
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [processing, setProcessing] = useState(0) // the csv flow after choosing file is still a work in progress
  const [newPayments, setNewPayments] = useState<newPaymentRow[]>([])
  const [failedLines, setFailedLines] = useState<string[][]>([])
  const [CSVHeaders, setCSVHeaders] = useState<string[]>([])
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentType, setPaymentType] = useState<string>('')
  const template_link =
    'https://firebasestorage.googleapis.com/v0/b/osmg-internal.appspot.com/o/Payment%20Template%20Orbit.csv?alt=media&token=10ec356f-403a-4e07-bd32-cec171ddc8ba'
  const onFileChange = (event) => {
    try {
      console.log(event.target.files[0].type)
      setSelectedFile(event.target.files[0])
      setPickedFile(true)
      processFile(event.target.files[0])
    } catch (err) {
      setPickedFile(false)
    }
  }
  async function processFile(selectedFile: File) {
    var newPayments: newPaymentRow[] = []
    var rejected_lines: string[][] = []
    var headers: any
    const file = await selectedFile.arrayBuffer()
    const workbook = read(file)
    const first_sheet = workbook.Sheets[workbook.SheetNames[0]]
    const results = utils.sheet_to_json(first_sheet, { header: 1 })

    const rows = results
    console.log('Rows being processed: ', rows.length)
    for (let i in rows) {
      const row = rows[i]
      if (i === '0') {
        headers = row
      } else {
        var newPayment: newPaymentRow = {
          file_id: '',
          value: '',
          month: '',
          year: '',
        }
        try {
          if (Array.isArray(row)) {
            if (row.length >= 4) {
              newPayment['file_id'] = String(row[0]).replace(/\D/g, '')
              newPayment['year'] = String(row[1]).replace(/\D/g, '')
              var month = String(row[2])
              month = month.substring(0, 3)
              var firstChar = month.charAt(0)
              var remainingLetters = month.slice(1)
              month = firstChar.toUpperCase() + remainingLetters.toLowerCase()
              newPayment['month'] = month
              newPayment['value'] = String(row[3])
                .replace('$', '')
                .replace(',', '')
                .replace(' ', '')

              // december payments applied should be applied the the year previous to the provided value.
              if (month === 'Dec' && paymentType == 'payments_applied') {
                newPayment['year'] = String(Number(newPayment['year']) - 1)
              }

              if (
                newPayment.file_id.length >= 3 &&
                newPayment.month.length === 3 &&
                newPayment.value.length > 0 &&
                newPayment.year.length === 4
              ) {
                newPayments.push(newPayment)
              } else {
                console.log(newPayment.year.length)
                if (row !== undefined) {
                  rejected_lines.push(row)
                }
              }
            } else {
              rejected_lines.push(row)
              console.log(
                'Row was under the expected number of columns and was rejected.'
              )
            }
          } else {
            console.log('row was not array: ', row)
          }
        } catch (err) {
          console.log('An Error Occured when parsing your csv.', row, err)
          if (Array.isArray(row)) {
            rejected_lines.push(row)
          } else {
            alert(
              'One for the rows is not an array and is being skipped. Check the console for more information.'
            )
            console.log('Non-array row: ', row)
          }
        }
      }
    }
    if (rejected_lines !== undefined) {
      console.log('rejected_lines count: ', rejected_lines.length)
      setFailedLines(rejected_lines)
    }
    if (newPayments !== undefined) {
      var combinedPayments: newPaymentRow[] = []
      console.log('Lines preserved: ', newPayments.length)
      var removed_lines = 0
      const starting_line_count = newPayments.length
      // check for multiple entries with identical file_id and time. If found these should be totalled.
      var indexes_to_remove: number[] = []
      for (let i = 0; i < newPayments.length; i++) {
        if (newPayments[i] !== undefined && !indexes_to_remove.includes(i)) {
          var selectedPayment = newPayments[i]
          if (i + 1 < newPayments.length) {
            for (let k = i + 1; k < newPayments.length; k++) {
              const comparisonPayment = newPayments[k]
              if (
                selectedPayment.file_id === comparisonPayment.file_id &&
                selectedPayment.year === comparisonPayment.year &&
                selectedPayment.month === comparisonPayment.month
              ) {
                if (parseFloat(comparisonPayment.value) > 0) {
                  const new_value =
                    parseFloat(selectedPayment.value) +
                    parseFloat(comparisonPayment.value)
                  newPayments[i].value = String(new_value)
                  indexes_to_remove.push(k)
                  removed_lines += 1
                }
              }
            }
          }
          combinedPayments.push(newPayments[i])
        }
      }
      // this for loop is for cosmetics not for functional use because of forgotten value issues.
      for (let i = 0; i < indexes_to_remove.length; i++) {
        newPayments.splice(indexes_to_remove[i], 1)
      }
      setNewPayments(combinedPayments)
      console.log('Removed Lines:', removed_lines)
      console.log('Original Quantity of Lines:', starting_line_count)
    }
    setCSVHeaders(headers)
    setProcessing(1)
  }
  function FileData() {
    if (isFilePicked && selectedFile) {
      return (
        <div style={{ paddingTop: 20 }}>
          <p>File Type: {selectedFile.type}</p>
          <p>File Name: {selectedFile.name}</p>
          <br />
          <br />
          {processing === 0 &&
          failedLines !== undefined &&
          newPayments !== undefined ? (
            <>
              <Loading />
              <br></br>
            </>
          ) : (
            <div>
              <div className="font-bold">
                File Processing Finished: <br />
              </div>
              Successfully Processed {newPayments?.length} rows.
              <br />
              Failed to Process {failedLines?.length} rows.
              <br />
              <div className="flex w-full py-4">
                <div className="w-full pr-10">
                  {newPayments !== undefined && newPayments.length > 0 && (
                    <div className="py-4">
                      New Payment Records to be Added:
                      <div className="max-h-96 w-fit overflow-auto border-2 shadow-sm">
                        <table className="table-auto">
                          <thead>
                            <tr>
                              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  File ID
                                </Typography>
                              </th>
                              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  Year
                                </Typography>
                              </th>
                              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  Month
                                </Typography>
                              </th>
                              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  Value
                                </Typography>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {newPayments.map((payment_row, index) => {
                              return (
                                <tr key={'review_payment_row_' + index}>
                                  <td
                                    key={'month_data_entry_file_id' + index}
                                    className="p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {payment_row.file_id}
                                    </Typography>
                                  </td>
                                  <td
                                    key={'month_data_entry_year' + index}
                                    className="p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {payment_row.year}
                                    </Typography>
                                  </td>
                                  <td
                                    key={'month_data_entry_month' + index}
                                    className="p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {payment_row.month}
                                    </Typography>
                                  </td>
                                  <td
                                    key={'month_data_entry_value' + index}
                                    className="p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {payment_row.value}
                                    </Typography>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full pl-10">
                  {failedLines !== undefined &&
                    failedLines.length > 0 &&
                    CSVHeaders !== undefined &&
                    CSVHeaders.length > 0 && (
                      <div className="py-4">
                        Lines that could not be Parsed:
                        <div className="max-h-80 w-fit overflow-auto border-2 shadow-sm">
                          <table className="table-auto">
                            <thead>
                              <tr>
                                {CSVHeaders.map((header, index) => {
                                  return (
                                    <th
                                      key={'failed_rows_header_' + index}
                                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                                    >
                                      <Typography
                                        placeholder="."
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                      >
                                        {header}
                                      </Typography>
                                    </th>
                                  )
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {failedLines.map((csv_row, index) => {
                                return (
                                  <tr key={'failed_payment_row_' + index}>
                                    {csv_row.map((value, index) => {
                                      return (
                                        <td
                                          key={'month_data_entry_' + index}
                                          className="p-2"
                                        >
                                          <Typography
                                            placeholder="."
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                          >
                                            {value}
                                          </Typography>
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      )
    } else {
      return <></>
    }
  }
  async function submitNewPayments(
    newPayments: newPaymentRow[],
    clients: client[],
    newPaymentType: string
  ) {
    const startTime = new Date().getTime()
    setSubmitting(true)

    var successful_writes: number = 0
    var unsuccessful_writes: number = 0
    var payments: paymentDoc[] = []
    const collectionSnapshot = await getDocs(collection(db, 'payments'))
    collectionSnapshot.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data()
        var payment_doc: paymentDoc = {
          file_id: '000',
          budget_original: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
          budget_adjustments: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
          payments_recieved: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
          payments_applied: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
        }
        const keys = [
          'file_id',
          'budget_original',
          'budget_adjustments',
          'payments_recieved',
          'payments_applied',
        ]
        for (let i = 0; i < keys.length; i++) {
          if (data[keys[i]] !== undefined) {
            if (paymentType !== keys[i] || !overwriteOnUpload) {
              // do not merge the current doc when overwrite is true
              payment_doc[keys[i]] = data[keys[i]]
            }
          }
        }
        payments.push(payment_doc)
      }
    })

    type submissions_group = {
      [key: string]: paymentDoc
    }
    var final_submission_docs: submissions_group = {}
    final_submission_docs = preparePaymentSubmissionDocuments(
      payments,
      newPayments,
      clients,
      newPaymentType
    )
    ;[successful_writes, unsuccessful_writes] =
      await writeSubmissionsToFirebase(final_submission_docs)
    const endTime = new Date().getTime()
    const time_difference = endTime - startTime
    const avg_time = time_difference / newPayments.length

    console.log('Successful Writes: ', successful_writes)
    console.log('Failed Writes: ', unsuccessful_writes)
    console.log('Average time per csv line in ms: ', avg_time)

    alert(
      'Successfully wrote payment information to ' +
        String(successful_writes) +
        ' clients.\nFailed to write payment information to ' +
        String(unsuccessful_writes) +
        ' clients.\nMore Information has been printed to console. \n(Ctrl + Shift + I)'
    )
    setSubmitting(false)
    resetPage()
    function preparePaymentSubmissionDocuments(
      old_payments: paymentDoc[],
      new_payment_entries: newPaymentRow[],
      clients: client[],
      newPaymentType: string
    ): submissions_group {
      var submissionDocuments: submissions_group = {}

      // finding all
      type translation_set = {
        [key: string]: string // file_id : client_id
      }
      var file_id_transations: translation_set = {} // file_id : client_id
      var client_id = ''
      for (let i = 0; i < new_payment_entries.length; i++) {
        const new_payment = new_payment_entries[i]
        ;[client_id, file_id_transations] = findClientID(
          new_payment.file_id,
          clients,
          file_id_transations
        )

        if (client_id !== '') {
          if (!Object.keys(submissionDocuments).includes(client_id)) {
            const index = findCurrentPaymentFile(old_payments, new_payment)
            const currentPaymentsFile = old_payments[index]
            const newPaymentDoc = addNewPaymentToPaymentDoc(
              currentPaymentsFile,
              new_payment,
              newPaymentType
            )
            submissionDocuments[client_id] = newPaymentDoc
          } else {
            const currentPaymentsFile = submissionDocuments[client_id]
            const newPaymentDoc = addNewPaymentToPaymentDoc(
              currentPaymentsFile,
              new_payment,
              newPaymentType
            )
            submissionDocuments[client_id] = newPaymentDoc
          }
        } else {
          console.log(
            'client ID was blank!!',
            'Payment: ',
            new_payment,
            'Was Skipped.'
          )
        }
      }

      return submissionDocuments

      function findClientID(
        file_id: string,
        clients: client[],
        translation_table: translation_set
      ): [string, translation_set] {
        var client_id: string = ''
        var new_table: translation_set = translation_table
        if (Object.keys(translation_table).includes(file_id)) {
          client_id = translation_table[file_id]
        } else {
          for (let i = 0; i < clients.length; i++) {
            if (clients[i].file_id === file_id) {
              const client = clients[i]
              new_table[file_id] = client.id
              client_id = client.id
            }
          }
        }
        return [client_id, translation_table]
      }
      function findCurrentPaymentFile(
        payments: paymentDoc[],
        newPayment: newPaymentRow
      ) {
        for (let i = 0; i < payments.length; i++) {
          if (
            payments[i] !== undefined &&
            payments[i].file_id !== undefined &&
            payments[i].file_id === newPayment.file_id
          ) {
            return i
          }
        }
        return -1
      }
      function addNewPaymentToPaymentDoc(
        payment_doc: paymentDoc,
        newPaymentEntry: newPaymentRow,
        paymentType: string
      ) {
        var payments_file: paymentDoc = {
          file_id: '000',
          budget_original: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
          budget_adjustments: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
          payments_recieved: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
          payments_applied: {
            '2024': {
              Jan: '0',
              Feb: '0',
              Mar: '0',
              Apr: '0',
              May: '0',
              Jun: '0',
              Jul: '0',
              Aug: '0',
              Sep: '0',
              Oct: '0',
              Nov: '0',
              Dec: '0',
            },
          },
        }
        if (payment_doc !== undefined) {
          payments_file = payment_doc
        }
        payments_file.file_id = newPaymentEntry.file_id
        const newPayment = newPaymentEntry
        try {
          // try to insert just the month's value, works when the structure is already complete.
          payments_file[paymentType][newPayment.year][newPayment.month] =
            newPayment.value
        } catch {
          try {
            // inserting new year dict since the above line shoudln't fail unless the year is missing.
            payments_file[paymentType][newPayment.year] = {
              Jan: 'N/A',
              Feb: 'N/A',
              Mar: 'N/A',
              Apr: 'N/A',
              May: 'N/A',
              Jun: 'N/A',
              Jul: 'N/A',
              Aug: 'N/A',
              Sep: 'N/A',
              Oct: 'N/A',
              Nov: 'N/A',
              Dec: 'N/A',
            }
            payments_file[paymentType][newPayment.year][newPayment.month] =
              newPayment.value
          } catch {
            payments_file[paymentType] = {
              '2023': {},
              '2024': {},
            }
            payments_file[paymentType][newPayment.year] = {
              Jan: 'N/A',
              Feb: 'N/A',
              Mar: 'N/A',
              Apr: 'N/A',
              May: 'N/A',
              Jun: 'N/A',
              Jul: 'N/A',
              Aug: 'N/A',
              Sep: 'N/A',
              Oct: 'N/A',
              Nov: 'N/A',
              Dec: 'N/A',
            }
            payments_file[paymentType][newPayment.year][newPayment.month] =
              newPayment.value
          }
        }
        return payments_file
      }
    }
    async function writeSubmissionsToFirebase(submissions: submissions_group) {
      var successful: number = 0
      var unsuccessful: number = 0
      const keys = Object.keys(submissions) // array of all client IDs

      var number_of_batches = Math.trunc(keys.length / 495) + 1 // max batch size if 500

      for (let i = 0; i < number_of_batches; i++) {
        var batch_successful_writes = 0
        var batch_unsuccessful_writes = 0
        const batch = writeBatch(db)
        for (let k = 0 + i * 495; k < 495 + i * 495; k++) {
          if (keys[k] !== undefined) {
            try {
              const paymentRef = doc(db, 'payments', keys[k])
              batch.set(paymentRef, submissions[keys[k]])
              batch_successful_writes += 1
            } catch (err) {
              batch_unsuccessful_writes += 1
              console.log(err)
              try {
                console.log(
                  'Failed to write submission # ' +
                    String(k) +
                    ' to firebase.\n',
                  submissions[keys[k]]
                )
              } catch (err) {
                console.log(err)
              }
            }
          }
        }
        try {
          await batch.commit()
          unsuccessful += batch_unsuccessful_writes
          successful += batch_successful_writes
        } catch (err) {
          unsuccessful += batch_successful_writes + batch_unsuccessful_writes
        }
      }

      for (let i = 0; i < keys.length; i++) {}

      return [successful, unsuccessful]
    }
  }
  function changePaymentInputType(new_type) {
    setPaymentType(new_type)
  }
  function resetPage() {
    setPickedFile(false)
    setSelectedFile(undefined)
    setProcessing(0)
    setNewPayments([])
    setFailedLines([])
    setCSVHeaders([])
    setSubmitting(false)
    setPaymentType('')
  }
  const [paymentDocCount, setPaymentDocCount] = useState<number | undefined>()
  async function getPaymentCount() {
    // collect active file_ids
    var all_file_ids: string[] = []
    clients.forEach((client) => {
      if (client.file_id && client.active) {
        all_file_ids.push(client.file_id)
      }
    })

    // setup num runs to workout around query IN's 30 item array limit.
    var num_runs = all_file_ids.length / 29 // remainder will cause additional run.

    var number_of_payment_docs = 0
    for (let i = 0; i < num_runs; i++) {
      var file_id_subset: string[] = []
      const starting_index = i * 29
      var ending_index = (i + 1) * 29
      if (ending_index >= all_file_ids.length) {
        file_id_subset = all_file_ids.slice(starting_index)
      } else {
        file_id_subset = all_file_ids.slice(starting_index, ending_index)
      }
      const coll = collection(db, 'payments')
      const q = query(coll, where('file_id', 'in', file_id_subset))
      const snapshot = await getCountFromServer(q)
      number_of_payment_docs += snapshot.data().count
    }
    setPaymentDocCount(number_of_payment_docs)
  }
  const [activeClientCount, setActiveClientCount] = useState<number>(0)
  async function getClientCount() {
    const coll = collection(db, 'clients')
    const q = query(coll, where('active', '==', true))
    const snapshot = await getCountFromServer(q)
    setActiveClientCount(snapshot.data().count)
  }
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad && clients) {
      getPaymentCount()
      getClientCount()
      setFirstLoad(false)
    }
  })

  const [overwriteOnUpload, setOverwriteOnUpload] = useState(true)
  const onMergeSwitchChange = (change) => {
    setOverwriteOnUpload(!overwriteOnUpload)
  }
  return (
    <div className="w-full">
      {submitting ? (
        <div className="w-full">
          <Typography placeholder={'.'} variant="h3">
            Submitting New Payments...
          </Typography>
          <div className="py-10">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-row flex-wrap">
          <div className="min-w-fit px-8">
            <div className="w-full">
              <Typography placeholder={'.'} variant="h5" className="w-full p-3">
                Add New Payment Set
              </Typography>
              <div className="py-4">
                <Menu>
                  <MenuHandler>
                    <Button
                      placeholder={'.'}
                      variant="outlined"
                      className=" border-blue-gray-700"
                    >
                      Payment Type: {paymentType}
                    </Button>
                  </MenuHandler>
                  <MenuList placeholder={undefined}>
                    <MenuItem
                      placeholder={'.'}
                      onClick={() => {
                        changePaymentInputType('budget_original')
                      }}
                    >
                      Budget Original
                    </MenuItem>
                    <MenuItem
                      placeholder={'.'}
                      onClick={() => {
                        changePaymentInputType('budget_adjustments')
                      }}
                    >
                      Budget Adjustments
                    </MenuItem>
                    <MenuItem
                      placeholder={'.'}
                      onClick={() => {
                        changePaymentInputType('payments_recieved')
                      }}
                    >
                      Payment Recieved
                    </MenuItem>
                    <MenuItem
                      placeholder={'.'}
                      onClick={() => {
                        changePaymentInputType('payments_applied')
                      }}
                    >
                      Payment Applied
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
              <div className="flex flex-row flex-wrap">
                Overwrite Data on Upload?
                <div className="px-2 py-1">
                  <Switch
                    defaultChecked
                    crossOrigin={'false'}
                    onChange={onMergeSwitchChange}
                  />
                </div>
              </div>
              {paymentType !== '' ? (
                <Input
                  crossOrigin="false"
                  type="file"
                  label="Payment Upload"
                  onChange={onFileChange}
                ></Input>
              ) : (
                <Input
                  crossOrigin="false"
                  disabled
                  type="file"
                  label="Payment Upload"
                ></Input>
              )}
            </div>
            <div className="p-4">
              {!isFilePicked ? (
                <div>
                  File Uploads must be of type CSV, and follow this template:
                  <br />
                  <a href={template_link} className="text-blue-500">
                    Payment Template
                  </a>
                </div>
              ) : (
                <div>
                  <FileData />
                </div>
              )}
            </div>
            {newPayments !== undefined && newPayments.length > 0 && (
              <div className="p-4">
                <Button
                  placeholder={'.'}
                  onClick={() => {
                    submitNewPayments(newPayments, clients, paymentType)
                  }}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
          <div className="min-w-fit px-8">
            <div className="w-full">
              <Typography placeholder={'.'} variant="h5" className="w-full p-3">
                Statistics
              </Typography>
              <div className="p-4">
                <Card
                  placeholder={'.'}
                  className="h-full w-full overflow-auto border-l border-r border-t"
                >
                  <table className="w-full min-w-max table-auto text-left">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td
                          className={
                            'p-4 border-b border-r border-blue-gray-50 '
                          }
                        >
                          <Typography
                            placeholder={'.'}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            Percent of Clients with Payment Doc
                          </Typography>
                        </td>
                        <td className={'p-4 border-b border-blue-gray-50'}>
                          <Typography
                            placeholder={'.'}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {paymentDocCount && paymentDocCount > 0 ? (
                              <>
                                {(
                                  (paymentDocCount / activeClientCount) *
                                  100
                                ).toFixed(1)}{' '}
                                %
                              </>
                            ) : (
                              <>0 %</>
                            )}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={
                            'p-4 border-b border-r border-blue-gray-50'
                          }
                        >
                          <Typography
                            placeholder={'.'}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            Payment Documents:
                          </Typography>
                        </td>
                        <td className={'p-4 border-b border-blue-gray-50'}>
                          <Typography
                            placeholder={'.'}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {paymentDocCount ? (
                              <>{paymentDocCount}</>
                            ) : (
                              <>Loading...</>
                            )}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={
                            'p-4 border-b border-r border-blue-gray-50'
                          }
                        >
                          <Typography
                            placeholder={'.'}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            Active Client Count:
                          </Typography>
                        </td>
                        <td className={'p-4 border-b  border-blue-gray-50'}>
                          <Typography
                            placeholder={'.'}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {activeClientCount}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
