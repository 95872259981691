import { useContext, useEffect, useState } from 'react'
import { account } from '../../../types/account'
import permissionsById from '../../../types/permissionsByID'
import { AccountsContext } from '../../../contexts'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'
import { db } from '../../../firebase'
import { permission } from '../../../types/permission'
import ensurePermissionType from '../../../functions/ensureType/ensurePermissionType'
import Loading from '../../../components/Loading'
import React from 'react'
import { Table } from 'antd'
import type { TableColumnsType, TableProps } from 'antd'
import PermissionsMatchDisplay from './PermissionsMatchDisplay'
import { Link } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/24/outline'

export default function ManageAccounts() {
  const accounts: account[] = useContext(AccountsContext)
  const [permissionsFileTemplates, setPermissionsFileTemplates] = useState<
    undefined | { [key: string]: permission }
  >()
  const [tableData, setTableData] = useState<TableLine[]>([])
  const [permissionsById, setPermissionsById] = useState<
    undefined | { [key: string]: permission }
  >()

  async function setupData() {
    var permissions_docs_by_uid: { [uid: string]: permission } = {}
    var permissions_by_id: permissionsById = {}
    var uid_by_id = {}

    const permissionsTemplatesSnap = await getDoc(
      doc(db, 'settings', 'permissions_file_templates')
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setPermissionsFileTemplates(docSnap.data())
      }
    })
    const permissionsSnapshot = await getDocs(collection(db, 'permissions'))
    permissionsSnapshot.forEach((doc) => {
      const data = doc.data()
      const uid = doc.id
      permissions_docs_by_uid[uid] = ensurePermissionType(data)
    })

    var employees: account[] = []
    accounts.forEach((account) => {
      if (account.internal) {
        employees.push(account)
        uid_by_id[account.id] = account.UID ? account.UID : 'no uid'
      }
    })

    Object.keys(uid_by_id).forEach((id) => {
      permissions_by_id[id] = permissions_docs_by_uid[uid_by_id[id]]
        ? permissions_docs_by_uid[uid_by_id[id]]
        : { title: 'no permissions found.' }
    })
    setPermissionsById(permissions_by_id)

    var table_data: TableLine[] = []
    employees.forEach((employee, index) => {
      table_data.push({
        key: index,
        name: employee.name ? employee.name : '',
        id: employee.id ? employee.id : '',
        title: employee.title ? employee.title : '',
        permissions_title: permissions_by_id[employee.id]
          ? permissions_by_id[employee.id]?.title
          : 'No Permissions',
        uid: employee.UID ? employee.UID : '',
      })
    })
    setTableData(table_data)
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad && accounts.length > 0) {
      setupData()
      setFirstLoad(false)
    }
  })
  interface TableLine {
    key: React.Key
    name: string
    title: string
    permissions_title: string
    id: string
    uid: string
  }

  const columns: TableColumnsType<TableLine> = [
    {
      title: 'Name',
      dataIndex: 'name',
      showSorterTooltip: { target: 'full-header' },
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => (
        <Link to={'/view/account#' + record.id}>{value}</Link>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      defaultSortOrder: 'descend',
      onFilter: (value, record) => record.title.indexOf(value as string) === 0,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions_title',
      defaultSortOrder: 'descend',
      onFilter: (value, record) =>
        record.permissions_title.indexOf(value as string) === 0,
      sorter: (a, b) => a.permissions_title.localeCompare(b.permissions_title),
    },
    {
      title: 'Orbit ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Manage',
      dataIndex: 'id',
      render: (value) => {
        return (
          <Link to={'manage_account#' + value}>
            <PencilIcon className="h-6 w-6" />
          </Link>
        )
      },
    },
  ]

  const onChange: TableProps<TableLine>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log('params', pagination, filters, sorter, extra)
  }

  return (
    <div className="w-full">
      {accounts && tableData ? (
        <div>
          <div className=" text-lg font-semibold text-black p-2">
            Orbit Accounts
          </div>
          <div className="flex flex-col w-full">
            <Table
              columns={columns}
              bordered
              dataSource={tableData}
              onChange={onChange}
              showSorterTooltip={{ target: 'sorter-icon' }}
            />
          </div>
        </div>
      ) : (
        <>
          <Loading />
        </>
      )}
    </div>
  )
}
