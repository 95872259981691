import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Radio,
  Typography,
} from '@material-tailwind/react'
import { account } from '../../../types/account'
import { CurrentUserContext, PermissionsContext } from '../../../contexts'
import { useContext, useEffect, useState } from 'react'
import { permission } from '../../../types/permission'
import { CheckIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Loading from '../../../components/Loading'
import { db } from '../../../firebase'
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import ensureAccountType from '../../../functions/ensureType/ensureAccountType'
import ensurePermissionType from '../../../functions/ensureType/ensurePermissionType'

export default function ManageSingleAccount() {
  const permissions: permission = useContext(PermissionsContext)
  const currentUser: account = useContext(CurrentUserContext)
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [subjectAccount, setSubjectAccount] = useState<account | undefined>()
  const [invalidAccount, setInvalidAccount] = useState<boolean>(false)

  const [permissions_presets, setPermissions] = useState({})
  const [permissionsCollected, setPermissionsCollected] =
    useState<boolean>(false)
  const [subjectPermissions, setSubjectPermissions] = useState<
    permission | undefined
  >()

  const [selectedPerRole, setSelectedPerRole] = useState<string>('')

  function handleDialog() {
    setDialogOpen(!dialogOpen)
  }

  function notYetBuilt() {
    alert("This function has not been built yet. Please try again later, or contact support.")
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  async function getAccount() {
    const tag = window.location.href.split('#').pop()
    const docRef = await getDoc(doc(db, 'accounts', String(tag)))
    if (docRef.exists()) {
      const data = docRef.data()
      setSubjectAccount(ensureAccountType(data))
    } else {
      setInvalidAccount(true)
    }
  }

  function getPermissions() {
    const permissionsDoc = doc(db, 'settings', 'permissions_file_templates')
    const unsub = onSnapshot(permissionsDoc, (querySnap) => {
      if (querySnap.exists()) {
        const data = querySnap.data()
        setPermissions(data)
      } else {
        alert(
          'There was an error loading permissions. Check internet contection and try again.'
        )
      }
    })
  }

  async function getSubjectAccountPermissions(subjectAccount: account) {
    if (subjectAccount.UID) {
      const permissionDoc = doc(db, 'permissions', subjectAccount.UID)
      const unsub = onSnapshot(permissionDoc, (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          setSubjectPermissions(ensurePermissionType(data))
        }
      })
    }
  }

  async function saveAccountsForNewPermissions() {
    try {
      const permissions_title = selectedPerRole
      var permissions_content = permissions_presets[selectedPerRole]
      permissions_content['title'] = permissions_title
      if (subjectAccount && subjectAccount.UID) {
        await setDoc(
          doc(db, 'permissions', subjectAccount.UID),
          permissions_content
        ).then(() => {
          setFirstLoad(true)
        })
      } else {
        alert(
          'Could not save account permissions. An Error with UID was encountered.'
        )
      }
    } catch (err) {
      console.log(err)
      alert('Error saving new permissions')
    }
    exitWithoutSaving()
  }

  function exitWithoutSaving() {
    setSelectedPerRole('')
    handleDialog()
  }

  useEffect(() => {
    if (firstLoad && currentUser) {
      getAccount()
      getPermissions()
      setFirstLoad(false)
    }
    if (permissions && subjectAccount) {
      setLoading(false)
    }
    if (subjectAccount && !permissionsCollected) {
      getSubjectAccountPermissions(subjectAccount)
      setPermissionsCollected(true)
    }
  })
  const role_titles = [
    'Organizer',
    'VP',
    'Director',
    'Business Manager',
    'Marketing',
    'Admin',
    'Default',
  ]
  const permission_keys = [
    'add_accounts',
    'edit_accounts',
    'edit_my_clients_accounts',
    'edit_current_user_account',
    'add_clients',
    'edit_all_clients',
    'edit_assigned_clients',
    'view_tiers',
    'edit_payments',
    'view_payments',
    'view_my_client_payments',
    'edit_all_roles',
    'edit_my_client_roles',
    'edit_all_contacts',
    'edit_my_client_contacts',
    'view_settings',
    'edit_presets',
    'edit_marketing',
    'view_marketing',
  ]

  return (
    <>
      <Card placeholder="." className="flex w-full flex-col p-4">
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <>
            {invalidAccount || subjectAccount === undefined ? (
              <>Invalid Account Returned.</>
            ) : (
              <div>
                <div className="flex flex-row justify-center">
                  <div className="flex-ro flex p-5">
                    <div className="">
                      {subjectAccount.avatar !== undefined &&
                      subjectAccount.avatar !== '' ? (
                        <Avatar
                          placeholder="Profile Photo not found."
                          src={subjectAccount.avatar}
                          className="h-40 w-40 p-2"
                        />
                      ) : (
                        <UserIcon className="h-40 w-40 p-2" />
                      )}
                    </div>
                    <div className="flex w-2/3 flex-grow flex-col justify-center pl-10 pt-3">
                      <Typography
                        placeholder="."
                        variant="h5"
                        color="blue-gray"
                      >
                        {subjectAccount.name}
                      </Typography>
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                      >
                        Work: {subjectAccount?.phone}
                      </Typography>
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                      >
                        Cell: {subjectAccount['Cell Phone']}
                      </Typography>
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                      >
                        {subjectAccount.email}
                      </Typography>
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                      >
                        {subjectAccount.title}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center">
                  <div>
                    {!permissionsCollected ? (
                      <Loading />
                    ) : (
                      <div>
                        {!subjectPermissions ? (
                          <div>No Permissions Details Found.</div>
                        ) : (
                          <div>
                            <Typography
                              placeholder="."
                              variant="h5"
                              color="blue-gray"
                            >
                              Permissions: {subjectPermissions.title}
                            </Typography>
                            <table className="overflow-clip">
                              <thead>
                                <tr>
                                  <th className="border border-blue-gray-100 bg-blue-gray-50 p-2">
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none opacity-70"
                                    >
                                      Permission Title
                                    </Typography>
                                  </th>{' '}
                                  <th className="border border-blue-gray-100 bg-blue-gray-50 p-2">
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none opacity-70"
                                    >
                                      Current Value
                                    </Typography>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {permission_keys.map((permission, index) => {
                                  const classes =
                                    'border-b border-blue-gray-50 p-2 w-24 border-r'
                                  return (
                                    <tr key={'role_line' + index}>
                                      <td
                                        className={
                                          'border-b border-l border-blue-gray-50 p-2 w-60 flex justify-start border-r'
                                        }
                                        key={'role_column_title_num' + index}
                                      >
                                        <Typography
                                          placeholder="."
                                          variant="small"
                                          color="blue-gray"
                                          className="flex justify-center font-normal"
                                        >
                                          {permission}
                                        </Typography>
                                      </td>
                                      <td
                                        className={classes}
                                        key={'role_column'}
                                      >
                                        <Typography
                                          placeholder="."
                                          variant="small"
                                          color="blue-gray"
                                          className="flex justify-center font-normal"
                                        >
                                          {subjectPermissions[permission] !==
                                          undefined ? (
                                            <div>
                                              {subjectPermissions[
                                                permission
                                              ] ? (
                                                <CheckIcon className="h-5 w-5 stroke-green-400" />
                                              ) : (
                                                <XMarkIcon className="h-5 w-5 stroke-red-400" />
                                              )}
                                            </div>
                                          ) : (
                                            <XMarkIcon className="h-5 w-5" />
                                          )}
                                        </Typography>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="pl-20">
                    <Card
                      placeholder={'Actions Card'}
                      className="pt-4 pb-6 pr-6 pl-6 border"
                    >
                      <Typography
                        placeholder="."
                        variant="h5"
                        color="blue-gray"
                      >
                        Actions
                      </Typography>
                      <a onClick={()=>{notYetBuilt()}} className="p-2 text-blue-700 hover:cursor-pointer">
                        Send Password Reset Email
                      </a>
                      <a
                        onClick={() => setDialogOpen(true)}
                        className="p-2 text-blue-700 hover:cursor-pointer"
                      >
                        Change Permissions
                      </a>
                      <a onClick={()=>{notYetBuilt()}} className="p-2 text-blue-700 hover:cursor-pointer">
                        Remove Access
                      </a>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Card>
      <Dialog
        placeholder="."
        open={dialogOpen}
        handler={handleDialog}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
        className="max-h-fit"
      >
        <DialogHeader placeholder=".">Select New Permissions</DialogHeader>
        <DialogBody placeholder="." className="h-96 overflow-auto">
          <div className="flex flex-col">
            {role_titles.map((key, index) => {
              return (
                <Radio
                  name="Permissions"
                  key={key + '_' + index}
                  label={key}
                  className="flex items-center gap-2"
                  onClick={() => setSelectedPerRole(key)}
                  crossOrigin={'false'}
                />
              )
            })}
          </div>
        </DialogBody>
        <DialogFooter placeholder=".">
          <>
            <Button placeholder="." variant="text" onClick={exitWithoutSaving}>
              <span>Close without Saving</span>
            </Button>
            {selectedPerRole === '' ? (
              <>
                <Button
                  placeholder="."
                  variant="gradient"
                  color="green"
                  disabled
                  onClick={saveAccountsForNewPermissions}
                >
                  <span>Save New Permissions</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  placeholder="."
                  variant="gradient"
                  color="green"
                  onClick={saveAccountsForNewPermissions}
                >
                  <span>Save New Permissions</span>
                </Button>
              </>
            )}
          </>
        </DialogFooter>
      </Dialog>
    </>
  )
}
