import Home from '../pages/Home'
import NotFound from '../pages/public/NotFound'
import Accounts from '../pages/accounts/AllAccounts'
import Clients from '../pages/clients/AllCleints'
import EditContacts from '../pages/clients/EditContacts'
import EditRoles from '../pages/clients/EditRoles'
import EditClient from '../pages/clients/EditClient'
import EditAccount from '../pages/accounts/EditAccount'
import AddClient from '../pages/clients/AddClient'
import AddAccount from '../pages/accounts/AddAccount'
import Settings from '../pages/settings/Settings'
import ChangeLog from '../pages/ChangeLog'
import About from '../pages/About'
import Editor from '../pages/Editor'
import Exports from '../pages/exports/Exports'
import KompassHandler from '../pages/Kompass/KompassHandler'
import AccountPageHandler from '../pages/accounts/AccountPageHandler'
import EditUPCs from '../components/EditUPCs'
import FAQ from '../pages/FAQ'
import SubmitFeedback from '../pages/SubmitFeedback'
import ReportBug from '../pages/ReportBug'
import WeeklyReportingList from '../pages/weeklys/WeeklyReportingList'
import WeeklyReportingEdit from '../pages/weeklys/WeeklyReportingEdit'
import NotificationPreferences from '../pages/NotificationsPreferences'
import ClientViewHandler from '../pages/clients/ViewClient/ClientViewHandler'
import PogMapper from '../pages/PogMapper.jsx'
import Services from '../pages/Services'
import Scorecards from '../pages/Scorecards'
import Testing from '../pages/Testing'
import ManageSingleAccount from '../pages/settings/ManageAccounts/ManageSingleAccount'
import MasterUpcList from '../pages/MasterUpcList'

export const private_routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/*',
    component: NotFound,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/accounts',
    component: Accounts,
  },
  {
    path: '/clients',
    component: Clients,
  },
  {
    path: 'services/kompass',
    component: KompassHandler,
  },
  {
    path: '/editor',
    component: Editor,
  },
  {
    path: '/export',
    component: Exports,
  },
  {
    path: '/changelog',
    component: ChangeLog,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: 'settings/manage_account',
    component: ManageSingleAccount,
  },
  {
    path: '/view/account',
    component: AccountPageHandler,
  },
  {
    path: '/view/client',
    component: ClientViewHandler,
  },
  {
    path: '/view/user',
    component: AccountPageHandler,
  },
  {
    path: '/add/account',
    component: AddAccount,
  },
  {
    path: '/add/client',
    component: AddClient,
  },
  {
    path: '/edit/account',
    component: EditAccount,
  },
  {
    path: '/edit/client',
    component: EditClient,
  },
  {
    path: '/edit/client/roles',
    component: EditRoles,
  },
  {
    path: '/edit/client/contacts',
    component: EditContacts,
  },
  {
    path: '/edit/client/upcs',
    component: EditUPCs,
  },
  {
    path: 'about',
    component: About,
  },
  {
    path: 'faq',
    component: FAQ,
  },
  {
    path: 'feedback',
    component: SubmitFeedback,
  },
  {
    path: 'report-issue',
    component: ReportBug,
  },
  {
    path: 'weekly-reporting',
    component: WeeklyReportingList,
  },
  {
    path: 'weekly-reporting/edit',
    component: WeeklyReportingEdit,
  },
  {
    path: 'notification_preferences',
    component: NotificationPreferences,
  },
  {
    path: 'services/pog_mapper',
    component: PogMapper,
  },
  {
    path: 'services',
    component: Services,
  },
  {
    path: 'services/scorecards',
    component: Scorecards,
  },
  {
    path: 'services/master_upc',
    component: MasterUpcList,
  },
  {
    path: '*',
    component: NotFound,
  },
  {
    path: 'testing',
    component: Testing,
  },
]
