import {
  Avatar,
  Badge,
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react'
import { changeNotification } from '../types/changeNotification'
import { useContext, useEffect, useState } from 'react'
import { account } from '../types/account'
import { client } from '../types/client'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
} from '../contexts'
import {
  AdjustmentsHorizontalIcon,
  ArrowRightIcon,
  BellIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Loading from './Loading'
import { db } from '../firebase'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import findNameFromAccountID from '../functions/findNameFromAccountID'
import swapValueForNameIfAccountID from '../functions/swapValueForNameIfAccountID'
import swapValueForNameIfClientID from '../functions/swapValueForNameIfClientID'
import { Link } from 'react-router-dom'

export default function NotificationsDropDown(props: {
  notifications: changeNotification[]
}) {
  const notifications: changeNotification[] = props.notifications.sort(
    (a, b) => {
      return b.exact_time_of_change.valueOf() - a.exact_time_of_change.valueOf()
    }
  )
  const [filteredNotifications, setFilteredNotifications] = useState<
    changeNotification[]
  >([])
  const accounts: account[] = useContext(AccountsContext)
  const clients: client[] = useContext(ClientsContext)
  const currentUser: account = useContext(CurrentUserContext)
  const [accountDocsByID, setAccountDocsByID] = useState({})
  const [clientDocsByID, setClientDocsByID] = useState({})

  function collectDocsByID(
    notifications: changeNotification[],
    accounts: account[],
    clients: client[]
  ) {
    // This function collects the clients and accounts which are relavent
    //  to the notifications, to limit the search space of client and
    // account details when rendering.
    var accountsByID = {}
    var clientsByID = {}

    var client_in_notification_set = false
    var account_in_notification_set = false

    var all_account_ids: string[] = []
    var all_client_ids: string[] = []

    notifications.forEach((notification) => {
      if (notification.subject_type === 'account') {
        account_in_notification_set = true
        all_account_ids.push(notification.subject_id)
      } else {
        client_in_notification_set = true
        all_client_ids.push(notification.subject_id)
      }
    })

    if (account_in_notification_set) {
      accounts.forEach((account) => {
        if (account.id && all_account_ids.includes(account.id)) {
          accountsByID[account.id] = account
        }
      })
    }
    if (client_in_notification_set) {
      clients.forEach((client) => {
        if (client.id && all_client_ids.includes(client.id)) {
          clientsByID[client.id] = client
        }
      })
    }
    setAccountDocsByID(accountsByID)
    setClientDocsByID(clientsByID)
  }
  async function deleteNotifications() {
    // deletes all docs in the notifications inbox.
    const querySnapshot = await getDocs(
      collection(db, 'accounts/' + currentUser.id + '/notifications')
    )
    const ids_to_delete: string[] = []
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        ids_to_delete.push(doc.id)
      }
    })
    ids_to_delete.forEach(async (id) => {
      await deleteDoc(
        doc(db, 'accounts/' + currentUser.id + '/notifications', id)
      )
    })
  }
  function notificationItem(
    img_src,
    img_variant,
    before,
    after,
    author_name,
    subject_name,
    subject_field,
    date_str,
    key,
    redirect_link
  ) {
    return (
      <Link
        to={redirect_link}
        onClick={() => {
          setOpenMenu(false)
        }}
      >
        <MenuItem
          placeholder={'.'}
          className={'p-1 flex flex-row h-fit w-full'}
          key={key}
        >
          <div className="h-full w-fit py-1 px-4">
            <Avatar
              placeholder={'.'}
              variant={img_variant}
              className="w-fit h-fit max-w-24 max-h-24 object-contain shadow-md"
              alt="Picture"
              src={img_src ? img_src : ''}
            />
          </div>
          <div className="h-full w-full flex flex-col justify-center align-middle">
            <div className="w-full h-fit flex flex-row justify-around align-middle">
              <Typography placeholder={'.'} variant="h6" color="blue-gray">
                {before}
              </Typography>
              <div className="p-1">
                <ArrowRightIcon className="h-4 w-4" />
              </div>
              <Typography placeholder={'.'} variant="h6" color="blue-gray">
                {after}
              </Typography>
            </div>
            <div className="w-full h-fit flex flex-row justify-left align-middle">
              <Typography
                placeholder={'.'}
                variant="small"
                color="gray"
                className="font-normal px-2"
              >
                {subject_name}
              </Typography>
              <Typography
                placeholder={'.'}
                variant="small"
                color="gray"
                className="font-normal px-0.5"
              >
                /
              </Typography>
              <Typography
                placeholder={'.'}
                variant="small"
                color="gray"
                className="font-normal px-2"
              >
                {subject_field}
              </Typography>
            </div>
            <div className="w-fit h-fit flex flex-row justify-between align-middle">
              <Typography
                placeholder={'.'}
                variant="small"
                color="gray"
                className="font-normal px-2"
              >
                By {author_name}
              </Typography>
              <Typography
                placeholder={'.'}
                variant="small"
                color="gray"
                className="font-normal px-2"
              >
                {date_str}
              </Typography>
            </div>
          </div>
        </MenuItem>
      </Link>
    )
  }
  function filterNotificationsByPref(
    notifications: changeNotification[],
    currentUser: account
  ): changeNotification[] {
    var filtered_notifs: changeNotification[] = []
    // it only takes one of the prefernece option filters for a notification to pass through
    function checkAllPrefKeys(notification, preferences) {
      var notification_is_permitted = false
      Object.keys(preferences).forEach((key) => {
        if (notification[key] && notification[key] === true) {
          if (preferences[key] === true) {
            notification_is_permitted = true
          }
        }
      })
      return notification_is_permitted
    }

    // if preferences availible, filter by options. Otherwise pass through all.
    if (currentUser.preferences) {
      return (filtered_notifs = notifications.filter(
        (notification: changeNotification) => {
          return checkAllPrefKeys(notification, currentUser.preferences)
        }
      ))
    } else {
      return notifications
    }
  }

  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (notifications && firstLoad && accounts && clients && currentUser) {
      setFirstLoad(false)
      const filtered_notifs = filterNotificationsByPref(
        notifications,
        currentUser
      )
      collectDocsByID(filtered_notifs, accounts, clients)
      setFilteredNotifications(filtered_notifs)
    }
  }, [clients, accounts, notifications])

  const [openMenu, setOpenMenu] = useState(false)
  return (
    <div className="overflow-visible">
      <Menu placement="bottom-end" handler={setOpenMenu} open={openMenu}>
        <MenuHandler className=" overflow-visible w-fit flex flex-row">
          <div className="flex flex-row">
            <IconButton
              variant="text"
              placeholder={'.'}
              className=" overflow-visible"
            >
              {filteredNotifications.length > 0 ? (
                <Badge content={filteredNotifications.length} color="green">
                  <BellIcon className="h-6 w-6" />
                </Badge>
              ) : (
                <BellIcon className="h-6 w-6" />
              )}
            </IconButton>
          </div>
        </MenuHandler>
        <MenuList placeholder={'.'} className="max-h-96 h-fit w-fit">
          {loading ? (
            <div className="p-8 flex justify-center w-full">
              <Loading />
            </div>
          ) : (
            <>
              {filteredNotifications.length > 0 ? (
                <MenuItem
                  placeholder={'.'}
                  onClick={() => {
                    setLoading(true)
                    deleteNotifications()
                  }}
                  className="p-0"
                >
                  <div className="w-full h-fit flex flex-row justify-center align-middle p-2 stroke-black">
                    <div className="py-1 px-2">Clear All</div>
                    <XMarkIcon className="h-6 w-6" stroke="black" />
                  </div>
                </MenuItem>
              ) : (
                <>
                  <div className="w-full h-fit flex flex-row justify-center align-middle p-2 stroke-black">
                    No New Notifications at this time.
                  </div>
                  <Link to="/notification_preferences">
                    <MenuItem placeholder={'.'}>
                      <div className="w-full h-fit flex flex-row justify-center align-middle p-2 stroke-black">
                        <AdjustmentsHorizontalIcon
                          className="h-6 w-6"
                          stroke="black"
                        />
                      </div>
                    </MenuItem>
                  </Link>
                </>
              )}
              <>
                {filteredNotifications.map((notification, index) => {
                  const author_name = findNameFromAccountID(
                    notification.author_id,
                    accounts
                  )
                  const before = swapValueForNameIfAccountID(
                    notification.old_value,
                    accounts
                  ) // client id should never be a changed value
                  const after = swapValueForNameIfAccountID(
                    notification.new_value,
                    accounts
                  )
                  if (notification.subject_type === 'account') {
                    const subject_name = swapValueForNameIfAccountID(
                      notification.subject_id,
                      accounts
                    )
                    const doc: account =
                      accountDocsByID[notification.subject_id]
                    if (doc)
                      return notificationItem(
                        doc.avatar,
                        'circular',
                        before,
                        after,
                        author_name,
                        subject_name,
                        notification.field_name,
                        notification.timestamp,
                        index + '_notification_item',
                        '/view/account#' + notification.subject_id
                      )
                  } else if (notification.subject_type === 'client') {
                    const subject_name = swapValueForNameIfClientID(
                      notification.subject_id,
                      clients
                    )
                    const doc = clientDocsByID[notification.subject_id]
                    if (doc)
                      return notificationItem(
                        doc.logo,
                        'rounded',
                        before,
                        after,
                        author_name,
                        subject_name,
                        notification.field_name,
                        notification.timestamp,
                        index + '_notification_item',
                        '/view/client#' + notification.subject_id
                      )
                  } else {
                    return <></>
                  }
                })}
              </>
            </>
          )}
        </MenuList>
      </Menu>
    </div>
  )
}
